<template>
  <footer class="footer items-center p-2 md:p-4 bg-base-200 text-base-content justify-between gap-2 flex">
    <div class="items-center">
      <p> {{$t('footer.copyright')}} <nuxt-link class="link" href="https://www.linkedin.com/in/brice--johnson/">{{$t('footer.briceJohnson')}}</nuxt-link></p>
    </div>
    <div
        :title="config.buildDate + ' | ' + config.gitCommit"
    >
      <nuxt-link class="link" to="/acknowledgements">{{$t('footer.acknowledgements')}}</nuxt-link>
    </div>
  </footer>
</template>

<script lang="ts" setup>
const {public: config} = useRuntimeConfig()



</script>
